:root {
  --bg-black: #000;
}

.hr {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.bg-nav {
  background-color: var(--bg-black);
}

.center-content {
  text-align: center;
}

.post-content img {
  max-width: 100%;
  height: auto;
}

.content-wrap {
  padding-top: 50px;
  padding-bottom: 50px;
}
$primary: #ffa800;
$nav-link: white;
$navbar-dark-color: white;
$navbar-dark-hover-color: #ffa800;

@import "node_modules/bootstrap/scss/bootstrap";

body {
  background-color: var(--bg-black);
  color: white;
}


.navbar-brand img, .offcanvas-title img {
  width: 200px;
  height: 50px;
}

.footer a {
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #ffcc00;
  }
}

.footer ul {
  margin-bottom: 0;
}

.footer li {
  display: inline-block;
  list-style: none;
  margin-right: 20px;
}

.img-hover:hover {
  opacity: 0.5;
  transition:opacity 500ms ease-out;

}

/*

====== Zoom effect ======

*/
.mfp-zoom-in {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}


